package cl.yoroi.app.web.comanda.styles

import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val PricingStyle = CssStyle {
    base {
        Modifier
            .fillMaxHeight(90.percent)
            .fillMaxWidth(74.percent)
            .transition(Transition.Companion.of(property = TransitionProperty.All, duration = 300.ms))
    }
    hover{
        Modifier
            .fillMaxHeight(95.percent)
            .fillMaxWidth(80.percent)
    }
}

val PricingRecommendedStyle = CssStyle {
    base {
        Modifier
            .fillMaxHeight(95.percent)
            .fillMaxWidth(74.percent)
            .transition(Transition.Companion.of(property = TransitionProperty.All, duration = 300.ms))
    }
    hover{
        Modifier
            .fillMaxHeight(100.percent)
            .fillMaxWidth(80.percent)
    }
}