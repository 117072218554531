package cl.yoroi.app.web.comanda.pages

import androidx.compose.runtime.*
import cl.yoroi.app.web.comanda.components.OverflowMenu
import cl.yoroi.app.web.comanda.components.app_feature.AppFeatureCard
import cl.yoroi.app.web.comanda.components.form.NewFeatureCard
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.core.Page
import org.jetbrains.compose.web.dom.Text
import com.varabyte.kobweb.worker.rememberWorker
import cl.yoroi.app.web.comanda.worker.EchoWorker
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.modifiers.backgroundImage
import com.varabyte.kobweb.compose.ui.modifiers.height
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun HomePage() {
    val worker = rememberWorker { EchoWorker { output -> console.log("Echoed: $output") } }
    var menuOpened  by remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        worker.postInput("Hello, worker!")
    }

    // TODO: Replace the following with your own content
    Box(
        Modifier
            .fillMaxSize()
            .backgroundImage(
                linearGradient(
                    dir = LinearGradient.Direction.ToRight,
                    from = Res.Theme.BROWN.color,
                    to = Res.Theme.BROWN.color
                )
            ),
        contentAlignment = Alignment.Center
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MainSection(
                onMenuClicked = {
                    menuOpened = true
                }
            )
            Box(
                modifier = Modifier.height(30.px)
            )

            var cont = 1
            Res.Arrays.FEATURES.forEach { feature ->
                AppFeatureCard(feature,cont)
                Box (modifier = Modifier.height(30.px))
                cont  += 1
            }

            PricingPage()

            Box(modifier = Modifier.height(50.px)) {}

            NewFeatureCard()

            if(menuOpened){
                OverflowMenu {
                    menuOpened = false
                }
            }
        }
    }
}
