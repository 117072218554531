package cl.yoroi.app.web.comanda.models

data class Feature(
    val id: String,
    val path : String,
    val name : String,
    val linkTitle: String,
    val description : String,
    val videoSrc : String
)
