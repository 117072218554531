package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEn : Strings {
  override val locale: String = "en"

  override val app_name: String = "Comanda App"

  override val app_slogan: String =
      "Take your orders without complex setups. Improve productivity with Comanda"

  override val app_description: String =
      "Comanda is a simple order taker for waiters that you can set up in a few steps. This give the waiters the ability to take orders from different tables in a very easy and fast way improving your productivity."

  override val soon_label: String = "(Coming soon!)"

  override val header_video: String = "/videos/comandaDemo.mp4"

  override val form_link: String = "https://forms.gle/Sftbhkuk4T1azApUA"

  override val feature1_name: String = "Add your products"

  override val feature1_link_title: String = "Products"

  override val feature1_description: String =
      "You can add your products from a google sheet. Once you added you can generate a QR code to share it easy with other waiters. The products stay on the device and you can use them offline."

  override val feature1_video: String = "/videos/products.mp4"

  override val feature2_name: String = "Share your order"

  override val feature2_link_title: String = "Orders"

  override val feature2_description: String =
      "You would be able tu share orders between waiters through a QR code if you needed."

  override val feature2_video: String = "/videos/shareOrder.mp4"

  override val feature3_name: String = "Print your order."

  override val feature3_link_title: String = "Print"

  override val feature3_description: String =
      "You would be able to print your order using a bluetooth printer or a printer that is shared with you in your private Network."

  override val feature3_video: String = "/videos/printOrder.mp4"

  override val feature4_name: String = "Generate and print daily summary."

  override val feature4_link_title: String = "Summary"

  override val feature4_description: String =
      "You would be able to print a daily summary with useful information such as the total sold by the device in the day and what type of payment the clients use."

  override val feature4_video: String = "/videos/printSummary.mp4"

  override val pricing_title: String = "Pricing"

  override val pricing_periodicity: String = "per month"

  override val pricing_recommended: String = "Recommended"

  override val pricing_free_title: String = "Free Plan"

  override val pricing_free_price: String = "${'$'}0"

  override val pricing_free_feature1: String = "With Ads"

  override val pricing_free_feature2: String = "Create unlimited Orders"

  override val pricing_free_feature3: String = "Print using bluetooth"

  override val pricing_free_feature4: String = "Change payment method"

  override val pricing_free_feature5: String = "Search for orders"

  override val pricing_free_feature6: String = "Scan QR code"

  override val pricing_free_feature7: String = "Create store settings QR"

  override val pricing_free_feature8: String = "Add custom QR to invoice"

  override val pricing_free_feature9: String = "Select Currency"

  override val pricing_free_feature10: String = "Edit store information"

  override val pricing_paid_title: String = "Premium Plan"

  override val pricing_paid_price: String = "${'$'}2.99 USD"

  override val pricing_paid_feature1: String = "NO ADS"

  override val pricing_paid_feature2: String = "Create unlimited Orders"

  override val pricing_paid_feature3: String = "Print using bluetooth"

  override val pricing_paid_feature4: String = "Change payment method"

  override val pricing_paid_feature5: String = "Search for orders"

  override val pricing_paid_feature6: String = "Scan QR code"

  override val pricing_paid_feature7: String = "Create store settings QR"

  override val pricing_paid_feature8: String = "Add custom QR to invoice"

  override val pricing_paid_feature9: String = "Select Currency"

  override val pricing_paid_feature10: String = "Edit store information"

  override val pricing_paid_feature11: String = "Print using network printers"

  override val pricing_paid_feature12: String = "Generate QR to share orders"

  override val pricing_paid_feature13: String = "Upload products from Google sheets"

  override val pricing_paid_feature14: String = "New features comming..."

  override val new_feature_title: String = "Recommend new features"

  override val new_feature_link_title: String = "New features"

  override val new_feature_description: String =
      "We are constantly adding new features to the app to meet the user needs. Enter to the form and suggest us new features for the app."

  override val new_feature_recommend: String = "Recommend"
}
