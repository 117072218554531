package cl.yoroi.app.web.comanda.utils

import cl.yoroi.app.web.comanda.models.Feature
import cl.yoroi.app.web.comanda.models.PricingFeature
import cl.yoroi.app.web.comanda.models.PricingType
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.components.icons.fa.FaCheck
import strings.ResStrings

object Res {

    val Res.locales get() = listOf("en", "es")

    enum class Theme(val color: Color) {
        Primary(color = Color.rgb(r = 34,g = 25,b = 22)),
        Secondary(color = Color.rgb(r = 219, g = 192, b = 91)),
        BLUE(color = Color.rgb(r = 33, g = 150, b = 243)),
        BROWN(color = Color.rgb(r = 34,g = 25,b = 22)),
        BROWN8A(color = Color.rgba(r = 34,g = 25,b = 22,a = .8f)),
        BROWN2A(color = Color.rgba(r = 34,g = 25,b = 22,a = .5f)),
        LIGHT_BLUE(color = Color.rgb(r = 168, g = 236, b = 255)),
        DARK_BLUE(color = Color.rgb(r = 34, g = 63, b = 94)),
        SOCIAL_ICON_BACKGROUND_LIGHT(color = Color.rgb(r = 230, g = 230, b = 230)),
        SOCIAL_ICON_BACKGROUND_DARK(color = Color.rgb(r = 48, g = 82, b = 118)),
        GRADIENT_ONE(color = Color.rgb(r = 255, g = 255, b = 255)),
        GRADIENT_ONE_DARK(color = Color.rgb(r = 19, g = 38, b = 58)),
        GRADIENT_TWO(color = Color.rgb(r = 255, g = 255, b = 255)),
        GRADIENT_TWO_DARK(color = Color.rgb(r = 20, g = 46, b = 73))
    }

    object Icon {
        const val EMAIL_LIGHT = "mail.svg"
        const val EMAIL_DARK = "mail_light.svg"
        const val GITHUB = "github.svg"
        const val GITHUB_LIGHT = "github_light.svg"
        const val INSTAGRAM = "instagram.svg"
        const val INSTAGRAM_LIGHT = "instagram_light.svg"
        const val GITLAB = "gitlab.svg"
        const val GITLAB_LIGHT = "gitlab_light.svg"
        const val SUN = "sun.svg"
        const val MOON = "moon.svg"
    }

    object Image {
        const val LOGO = "images/logo.png"
        const val BACKGROUND  = "images/waiterTablet.png"//"images/background.svg"
        const val BACKGROUNDSM  = "images/waiterTabletSM.png"
    }

    object String {
        const val APP_NAME  ="Comanda App"
        const val NAME = "Stefan Jovanovic"
        const val PROFESSION = "Kotlin Multiplaform Developer"
        const val ABOUT_ME =
            "Lorem ipsum dolor sit amet, nisl tempus lorem adipiscing elit. Donec sollicitudin blandit tellus, ac sagittis ligula egestas consequat. Lorem ipsum dolor sit amet."
        const val BUTTON_TEXT_ANDROID = "App Android"
        const val BUTTON_TEXT_IOS = "App iOS"
        const val ROBOTO_CONDENSED = "RobotoCondensedBold"
        const val ROBOTO_REGULAR = "RobotoRegular"
        const val YOUTUBE_URL= "https://www.youtube.com/@ComandaApp-n7v"
        const val ANDROID_URL  =  "https://play.google.com/store/apps/details?id=cl.yoroi.app.restre.androidApp"
        const val IOS_URL = ""
        const val MY_EMAIL = "mailto:stefan.jovanavich@gmail.com"
        const val SAVED_THEME = "theme"
    }

    object Dimens {
        const val BORDER_RADIUS = 8
        const val SECTION_WIDTH = 1920
        const val MAX_CARD_WIDTH = 1200
        const val MAX_CARD_HEIGHT = 600
        const val ICON_SIZE = 24
        const val ICON_SIZE_LG = 32
    }

    object Arrays {
        val FEATURES = listOf(
            Feature(
                "productos",
                "#productos",
                ResStrings.feature1_name,
                ResStrings.feature1_link_title,
                ResStrings.feature1_description,
                ResStrings.feature1_video
            ),
            Feature(
                "ordenes",
                "#ordenes",
                ResStrings.feature2_name,
                ResStrings.feature2_link_title,
                ResStrings.feature2_description,
                ResStrings.feature2_video
            ),
            Feature(
                "imprime",
                "#imprime",
                ResStrings.feature3_name,
                ResStrings.feature3_link_title,
                ResStrings.feature3_description,
                ResStrings.feature3_video
            ),
            Feature(
                "resumen",
                "#resumen",
                ResStrings.feature4_name,
                ResStrings.feature4_link_title,
                ResStrings.feature4_description,
                ResStrings.feature4_video
            )
        )
        val PRICING_TYPES = listOf(
            PricingType(
                name = ResStrings.pricing_free_title,
                price = ResStrings.pricing_free_price,
                recommended = false,
                periodicity = ResStrings.pricing_periodicity,
                features = listOf(
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature1
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature2
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature3
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature4
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature5
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature6
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature7
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature8
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature9
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_free_feature10
                    )

                )
            ),
            PricingType(
                name = ResStrings.pricing_paid_title,
                price = ResStrings.pricing_paid_price,
                recommended = true,
                periodicity = ResStrings.pricing_periodicity,
                features = listOf(
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature1
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature2
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature3
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature4
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature5
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature6
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature7
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature8
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature9
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature10
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature11
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature12
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature13
                    ),
                    PricingFeature(
                        icon = {
                            FaCheck(modifier = it)
                        },
                        title = ResStrings.pricing_paid_feature14
                    )

                )
            )
        )
    }


}