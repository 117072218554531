package cl.yoroi.app.web.comanda.components

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.styles.SocialLinkStyle
import cl.yoroi.app.web.comanda.utils.Res.String.YOUTUBE_URL
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaYoutube
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.AlignSelf
import org.jetbrains.compose.web.css.px

@Composable
fun SocialBar(row: Boolean = false) {
    if(row){
        Row(
            modifier = Modifier
                .fillMaxHeight()
                .alignSelf(AlignSelf.SelfEnd)
                .padding(leftRight = 25.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            SocialLinks(true)
        }
    }else {
        Column(
            modifier = Modifier
                .margin(right = 25.px)
                .padding(topBottom = 25.px)
                .minWidth(40.px)
                .borderRadius(r = 20.px)
                .backgroundColor(Colors.White),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SocialLinks()
        }
    }

}

@Composable
private fun SocialLinks(row: Boolean = false){
//    Link(
//        path = ALTAVOZ_URL
//    ){
//        FaIdCard(
//            modifier = SocialLinkStyle.toModifier()
//                .margin(
//                    bottom = if(row) 0.px else 40.px,
//                    right = if (row) 40.px else 0.px
//                ),
//            size = IconSize.LG
//        )
//    }
    Link(
        path = YOUTUBE_URL
    ){
        FaYoutube(
            modifier = SocialLinkStyle.toModifier()
                .margin(
                    bottom = if(row) 0.px else 40.px,
                    right = if (row) 40.px else 0.px
                ),
            size = IconSize.LG
        )
    }
//    Link(
//        path = INSTAGRAM_URL
//    ){
//        FaInstagram(
//            modifier = SocialLinkStyle.toModifier()
//                .margin(
//                    bottom = if(row) 0.px else 40.px,
//                    right = if (row) 40.px else 0.px
//                ),
//            size = IconSize.LG
//        )
//    }
//    Link(
//        path = LINKEDIN_URL
//    ){
//        FaLinkedin(
//            modifier = SocialLinkStyle.toModifier(),
//            size = IconSize.LG
//        )
//    }
}





