package cl.yoroi.app.web.comanda.components.form

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.models.Feature
import cl.yoroi.app.web.comanda.styles.MainImageStyle
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.RectF
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Source
import org.jetbrains.compose.web.dom.Video
import org.w3c.dom.Attr

@Composable
fun RightSide(
    breakpoint: Breakpoint
)
{
    Box(
       modifier = Modifier
           .fillMaxWidth()
           .thenIf(
               condition = breakpoint > Breakpoint.MD,
               other = Modifier.height((Res.Dimens.MAX_CARD_HEIGHT - 24).px)
           )
    ){
        Image(
            modifier = MainImageStyle.toModifier().fillMaxSize().clip(RectF(20.px)),
            src = "images/newFeature.png",
        )
    }
}