package cl.yoroi.app.web.comanda

import androidx.compose.runtime.*
import cl.yoroi.app.web.comanda.utils.Res
import cl.yoroi.app.web.comanda.utils.Res.locales
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.palette.button
import io.github.skeptick.libres.LibresSettings
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
const val LOCALE_KEY = "locale"

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    LibresSettings.languageCode =
        localStorage.getItem(LOCALE_KEY)
            ?: Res.locales.find { window.navigator.language.contains(it) }
                    ?: Res.locales.first()
    SilkApp {
        Surface(SmoothColorStyle.toModifier().minHeight(100.vh)) {
            content()
        }
    }
}

@InitSilk
fun overrideSilkTheme(context:  InitSilkContext){
    context.apply {
        theme.palettes.apply {
            light.apply {
                button.apply {
                    default = Res.Theme.Primary.color
                    hover =  Res.Theme.Primary.color
                    pressed=  Res.Theme.Primary.color
                }
            }
        }
    }
}
