package cl.yoroi.app.web.comanda.styles

import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.grayscale
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.anyLink
import com.varabyte.kobweb.silk.style.selectors.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

val NavigationItemStyle = CssStyle {
    base {
        Modifier
            .color(Res.Theme.Secondary.color)
            .transition(Transition.of(property = "color", duration = 200.ms))
    }

    anyLink{
        Modifier
            .color(Res.Theme.Primary.color)
    }

    hover  {
        Modifier
            .color(Res.Theme.Primary.color)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val LogoStyle = CssStyle {

    base{

        Modifier
            .transform { rotate(0.deg) }
            .transition(Transition.of(property = "transform", duration = 200.ms))
    }
    hover{
        Modifier
            .transform { rotate((-10).deg) }
    }
}

val SocialLinkStyle = CssStyle {
    base {
        Modifier
            .color(Res.Theme.BROWN.color)
            .transition(Transition.of(property = "color", duration = 200.ms))
    }

    anyLink{
        Modifier
            .color(Res.Theme.BROWN.color)
    }

    hover  {
        Modifier
            .color(Res.Theme.Primary.color)
    }
}

val MainButtonStyle = CssStyle  {
    base{
        Modifier
            .width(100.px)
            .transition(Transition.of(property ="width", duration = 200.ms))
    }

    hover{
        Modifier.width(120.px)
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val  MainImageStyle = CssStyle{
    base{
        Modifier
            .styleModifier {
                filter { grayscale(100.percent) }
            }
            .transition(Transition.of(property ="filter", duration = 200.ms))
    }

    hover{
        Modifier.styleModifier {
            filter { grayscale(0.percent) }
        }
    }
}