package cl.yoroi.app.web.comanda.components.app_feature

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.models.Feature
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.shapes.RectF
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.document
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Source
import org.jetbrains.compose.web.dom.Video
import org.w3c.dom.Attr

@Composable
fun RightSide(
    breakpoint: Breakpoint,
    feature: Feature
)
{
    Box(
       modifier = Modifier
           .fillMaxWidth()
           .thenIf(
               condition = breakpoint > Breakpoint.MD,
               other = Modifier.height((Res.Dimens.MAX_CARD_HEIGHT - 24).px)
           )
    ){
        Video(
            attrs = Modifier
                .fillMaxSize()
                .clip(RectF(15.px))
                .attrsModifier {
                    attr("id","movie")
                    attr("controls","false")
                    attr("autoplay","")
                    attr("muted","muted")
                    attr("loop", "")
                }
                .toAttrs(),
        ){
            Source(
                attrs = {
                    attr("src",feature.videoSrc)
                    attr("type","video/mp4")
                }
            )
        }
    }
}