package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String

public object StringsEs : Strings {
  override val locale: String = "es"

  override val app_name: String = "Comanda App"

  override val app_slogan: String =
      "Toma las comandas de forma simple, sin configuraciones complejas. Mejora la productividad con Comanda"

  override val app_description: String =
      "Comanda te permite comenzar a utilizar el sistema en tan solo unos pasos, para que los camareros puedan tomar pedidos en las mesas, haciendo más fácil y rápido el funcionamiento de tu establecimiento."

  override val soon_label: String = "(Pronto!)"

  override val header_video: String = "/videos/comandaDemo.mp4"

  override val form_link: String = "https://forms.gle/AcgWeWPS5DXtsskC9"

  override val feature1_name: String = "Agrega tus productos"

  override val feature1_link_title: String = "Productos"

  override val feature1_description: String =
      "Podrás agregar tus productos desde una planilla google sheets. Luego fácilmente compartirla con los demás usuarios. Los productos quedan cargados en la aplicación para que funcione de manera offline."

  override val feature1_video: String = "/videos/products.mp4"

  override val feature2_name: String = "Comparte tu orden"

  override val feature2_link_title: String = "Ordenes"

  override val feature2_description: String =
      "Podrás compartir ordenes entre los garzones con un código QR si así lo necesitas"

  override val feature2_video: String = "/videos/shareOrder.mp4"

  override val feature3_name: String = "Imprime tu orden"

  override val feature3_link_title: String = "Imprime"

  override val feature3_description: String =
      "Podrás imprimir tu orden con impresora bluetooth o alguna que esté conectada desde la red."

  override val feature3_video: String = "/videos/printOrder.mp4"

  override val feature4_name: String = "Genera e imprime el resumen del día."

  override val feature4_link_title: String = "Resumen"

  override val feature4_description: String =
      "Podrás generar e imprimir el resumen del día, te entregará información de cual fue el total de la venta del dispositivo y con que medios de pago se realizaron."

  override val feature4_video: String = "/videos/printSummary.mp4"

  override val pricing_title: String = "Precios"

  override val pricing_periodicity: String = "mensuales"

  override val pricing_recommended: String = "Recomendado"

  override val pricing_free_title: String = "Plan Gratuito"

  override val pricing_free_price: String = "${'$'}0"

  override val pricing_free_feature1: String = "Con Publicidad"

  override val pricing_free_feature2: String = "Crea ordenes ilimitadas"

  override val pricing_free_feature3: String = "Imprime usando bluetooth"

  override val pricing_free_feature4: String = "Cambia el método de pago"

  override val pricing_free_feature5: String = "Buscar por ordenes"

  override val pricing_free_feature6: String = "Escanear código QR "

  override val pricing_free_feature7: String = "Crea QR con la configuración de la tienda"

  override val pricing_free_feature8: String = "Agregar QR customizado para la boleta"

  override val pricing_free_feature9: String = "Selecciona la moneda"

  override val pricing_free_feature10: String = "Editar Información"

  override val pricing_paid_title: String = "Plan Premium"

  override val pricing_paid_price: String = "${'$'}2.99 USD"

  override val pricing_paid_feature1: String = "SIN Publicidad"

  override val pricing_paid_feature2: String = "Crea ordenes ilimitadas"

  override val pricing_paid_feature3: String = "Imprime usando bluetooth"

  override val pricing_paid_feature4: String = "Cambia el método de pago"

  override val pricing_paid_feature5: String = "Buscar por ordenes"

  override val pricing_paid_feature6: String = "Escanear código QR "

  override val pricing_paid_feature7: String = "Crea QR con la configuración de la tienda"

  override val pricing_paid_feature8: String = "Agregar QR customizado para la boleta"

  override val pricing_paid_feature9: String = "Selecciona la moneda"

  override val pricing_paid_feature10: String = "Editar Información"

  override val pricing_paid_feature11: String = "Imprime usando impresoras disponibles en la Red"

  override val pricing_paid_feature12: String = "Generar QR para compartir ordenes"

  override val pricing_paid_feature13: String = "Carga productos desde Google sheets"

  override val pricing_paid_feature14: String = "Nuevas funcionalidades en camino..."

  override val new_feature_title: String = "Recomienda nuevas funcionalidades"

  override val new_feature_link_title: String = "Nuevas funcionalidades"

  override val new_feature_description: String =
      "Estamos constantemente agregando nuevas funcionalidades al la aplicación para cumplir con las necesidades de nuestros usuarios. Llena el formulario y envíanos tus sugerencias de nuevas funcionalidades para la aplicación."

  override val new_feature_recommend: String = "Recomienda"
}
