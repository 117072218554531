package cl.yoroi.app.web.comanda.components

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.styles.LogoStyle
import cl.yoroi.app.web.comanda.styles.NavigationItemStyle
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.Shape
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import strings.ResStrings

@Composable
fun Header(onMenuClicked: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    Row(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint> Breakpoint.MD) 80.percent else 90.percent)
            .margin(topBottom = 50.px),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically

    ){
        LeftSide(breakpoint,onMenuClicked)
        if (breakpoint > Breakpoint.MD) {
            RightSide()
        }
    }
}

@Composable
fun LeftSide(
    breakpoint: Breakpoint,
    onMenuClicked : () -> Unit
){
    Row (verticalAlignment = Alignment.CenterVertically){
        if(breakpoint <= Breakpoint.MD) {
            FaBars(
                modifier = Modifier.margin(right = 15.px)
                    .color(Res.Theme.Secondary.color)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        onMenuClicked()
                    },
                size = IconSize.XL
            )
        }
        Image(
            modifier = LogoStyle.toModifier().width(80.px).height(80.px).clip(Circle()),
            src = Res.Image.LOGO
        )
    }
}

@Composable
fun RightSide(){
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .borderRadius(r= 50.px)
            .background(Res.Theme.Secondary.color)
            .padding(20.px),
        horizontalArrangement = Arrangement.End

    ){

        Res.Arrays.FEATURES.forEach {section ->
            Link(
                modifier = NavigationItemStyle.toModifier()
                    .padding(right= 30.px)
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .fontSize(18.px)
                    .fontWeight(FontWeight.Normal)
                    .textDecorationLine(textDecorationLine = TextDecorationLine.None),
                path = section.path,
                text = section.linkTitle
            )

        }
        Link(
            modifier = NavigationItemStyle.toModifier()
                .padding(right= 30.px)
                .fontFamily(Res.String.ROBOTO_REGULAR)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .textDecorationLine(textDecorationLine = TextDecorationLine.None),
            path = "#pricing",
            text = ResStrings.pricing_title
        )
        Link(
            modifier = NavigationItemStyle.toModifier()
                .padding(right= 30.px)
                .fontFamily(Res.String.ROBOTO_REGULAR)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .textDecorationLine(textDecorationLine = TextDecorationLine.None),
            path = "#newfeature",
            text = ResStrings.new_feature_link_title
        )
        SocialBar(true)

    }
}