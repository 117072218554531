package cl.yoroi.app.web.comanda.components.app_feature

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.models.Feature
import cl.yoroi.app.web.comanda.styles.ButtonStyle
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.icons.fa.FaAndroid
import com.varabyte.kobweb.silk.components.icons.fa.FaApple
import com.varabyte.kobweb.silk.components.icons.fa.FaPhone
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.window
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import strings.ResStrings

@Composable
fun LeftSide(
    breakpoint: Breakpoint,
    feature: Feature
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(all = 50.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = if(breakpoint <= Breakpoint.SM)
        Alignment.CenterHorizontally else Alignment.Start
    ) {
        SpanText(
            text = feature.name,
            modifier = Modifier
                .margin(bottom = 12.px)
                .fontFamily(Res.String.ROBOTO_CONDENSED)
                .color(Colors.Black)
                .fontSize(50.px)
                .fontWeight(FontWeight.Bold)
                .textAlign(
                    if(breakpoint <= Breakpoint.SM) TextAlign.Center
                    else TextAlign.Start
                )
        )
        Surface(
            modifier = Modifier
                .height(4.px)
                .width(40.px)
                .margin(bottom = 24.px)
                .background(Res.Theme.BROWN.color)
                .align(
                    if(breakpoint <= Breakpoint.SM) Alignment.CenterHorizontally
                    else Alignment.Start
                )
        ){}
        SpanText(
            text = feature.description,
            modifier = Modifier
                .margin(bottom = 36.px)
                .fontFamily(Res.String.ROBOTO_REGULAR)
                .color(Colors.Black)
                .opacity(50.percent)
                .lineHeight(2)
                .fontSize(14.px)
                .fontWeight(FontWeight.Bold)
                .textAlign(
                    if(breakpoint <= Breakpoint.SM) TextAlign.Center
                    else TextAlign.Start
                )
        )
        Button(
            modifier = ButtonStyle.toModifier().margin(bottom = 20.px),
            size = ButtonSize.LG,
            onClick = {
                window.location.href = Res.String.ANDROID_URL
            }
        ){
            FaAndroid(
                modifier = Modifier.margin(right = 12.px).color(Res.Theme.Secondary.color)
            )
            SpanText(
                modifier = Modifier
                    .fontSize(14.px)
                    .color(Res.Theme.Secondary.color)
                    .fontWeight(FontWeight.Bold)
                    .fontFamily(Res.String.ROBOTO_REGULAR),
                text = Res.String.BUTTON_TEXT_ANDROID
            )
        }
        Button(
            modifier = ButtonStyle.toModifier().margin(bottom = 50.px),
            enabled = false,
            size = ButtonSize.LG,
            onClick = {
                window.location.href = Res.String.IOS_URL
            }
        ){
            FaApple(
                modifier = Modifier.margin(right = 12.px).color(Res.Theme.Secondary.color)
            )
            SpanText(
                modifier = Modifier
                    .fontSize(14.px)
                    .color(Res.Theme.Secondary.color)
                    .fontWeight(FontWeight.Bold)
                    .fontFamily(Res.String.ROBOTO_REGULAR),
                text = Res.String.BUTTON_TEXT_IOS + " " + ResStrings.soon_label
            )
        }

    }
}