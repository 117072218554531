package strings

import io.github.skeptick.libres.strings.PluralForms
import io.github.skeptick.libres.strings.getCurrentLanguageCode
import kotlin.String
import kotlin.collections.Map

public object ResStrings {
  private val baseLocale: StringsEn = StringsEn

  private val locales: Map<String, Strings> = mapOf("es" to StringsEs, "en" to StringsEn)

  public val locale: String
    get() = locales[getCurrentLanguageCode()]?.locale ?: baseLocale.locale

  public val app_name: String
    get() = locales[getCurrentLanguageCode()]?.app_name ?: baseLocale.app_name

  public val app_slogan: String
    get() = locales[getCurrentLanguageCode()]?.app_slogan ?: baseLocale.app_slogan

  public val app_description: String
    get() = locales[getCurrentLanguageCode()]?.app_description ?: baseLocale.app_description

  public val soon_label: String
    get() = locales[getCurrentLanguageCode()]?.soon_label ?: baseLocale.soon_label

  public val header_video: String
    get() = locales[getCurrentLanguageCode()]?.header_video ?: baseLocale.header_video

  public val form_link: String
    get() = locales[getCurrentLanguageCode()]?.form_link ?: baseLocale.form_link

  public val feature1_name: String
    get() = locales[getCurrentLanguageCode()]?.feature1_name ?: baseLocale.feature1_name

  public val feature1_link_title: String
    get() = locales[getCurrentLanguageCode()]?.feature1_link_title ?: baseLocale.feature1_link_title

  public val feature1_description: String
    get() = locales[getCurrentLanguageCode()]?.feature1_description ?:
        baseLocale.feature1_description

  public val feature1_video: String
    get() = locales[getCurrentLanguageCode()]?.feature1_video ?: baseLocale.feature1_video

  public val feature2_name: String
    get() = locales[getCurrentLanguageCode()]?.feature2_name ?: baseLocale.feature2_name

  public val feature2_link_title: String
    get() = locales[getCurrentLanguageCode()]?.feature2_link_title ?: baseLocale.feature2_link_title

  public val feature2_description: String
    get() = locales[getCurrentLanguageCode()]?.feature2_description ?:
        baseLocale.feature2_description

  public val feature2_video: String
    get() = locales[getCurrentLanguageCode()]?.feature2_video ?: baseLocale.feature2_video

  public val feature3_name: String
    get() = locales[getCurrentLanguageCode()]?.feature3_name ?: baseLocale.feature3_name

  public val feature3_link_title: String
    get() = locales[getCurrentLanguageCode()]?.feature3_link_title ?: baseLocale.feature3_link_title

  public val feature3_description: String
    get() = locales[getCurrentLanguageCode()]?.feature3_description ?:
        baseLocale.feature3_description

  public val feature3_video: String
    get() = locales[getCurrentLanguageCode()]?.feature3_video ?: baseLocale.feature3_video

  public val feature4_name: String
    get() = locales[getCurrentLanguageCode()]?.feature4_name ?: baseLocale.feature4_name

  public val feature4_link_title: String
    get() = locales[getCurrentLanguageCode()]?.feature4_link_title ?: baseLocale.feature4_link_title

  public val feature4_description: String
    get() = locales[getCurrentLanguageCode()]?.feature4_description ?:
        baseLocale.feature4_description

  public val feature4_video: String
    get() = locales[getCurrentLanguageCode()]?.feature4_video ?: baseLocale.feature4_video

  public val pricing_title: String
    get() = locales[getCurrentLanguageCode()]?.pricing_title ?: baseLocale.pricing_title

  public val pricing_periodicity: String
    get() = locales[getCurrentLanguageCode()]?.pricing_periodicity ?: baseLocale.pricing_periodicity

  public val pricing_recommended: String
    get() = locales[getCurrentLanguageCode()]?.pricing_recommended ?: baseLocale.pricing_recommended

  public val pricing_free_title: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_title ?: baseLocale.pricing_free_title

  public val pricing_free_price: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_price ?: baseLocale.pricing_free_price

  public val pricing_free_feature1: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature1 ?:
        baseLocale.pricing_free_feature1

  public val pricing_free_feature2: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature2 ?:
        baseLocale.pricing_free_feature2

  public val pricing_free_feature3: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature3 ?:
        baseLocale.pricing_free_feature3

  public val pricing_free_feature4: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature4 ?:
        baseLocale.pricing_free_feature4

  public val pricing_free_feature5: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature5 ?:
        baseLocale.pricing_free_feature5

  public val pricing_free_feature6: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature6 ?:
        baseLocale.pricing_free_feature6

  public val pricing_free_feature7: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature7 ?:
        baseLocale.pricing_free_feature7

  public val pricing_free_feature8: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature8 ?:
        baseLocale.pricing_free_feature8

  public val pricing_free_feature9: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature9 ?:
        baseLocale.pricing_free_feature9

  public val pricing_free_feature10: String
    get() = locales[getCurrentLanguageCode()]?.pricing_free_feature10 ?:
        baseLocale.pricing_free_feature10

  public val pricing_paid_title: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_title ?: baseLocale.pricing_paid_title

  public val pricing_paid_price: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_price ?: baseLocale.pricing_paid_price

  public val pricing_paid_feature1: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature1 ?:
        baseLocale.pricing_paid_feature1

  public val pricing_paid_feature2: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature2 ?:
        baseLocale.pricing_paid_feature2

  public val pricing_paid_feature3: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature3 ?:
        baseLocale.pricing_paid_feature3

  public val pricing_paid_feature4: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature4 ?:
        baseLocale.pricing_paid_feature4

  public val pricing_paid_feature5: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature5 ?:
        baseLocale.pricing_paid_feature5

  public val pricing_paid_feature6: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature6 ?:
        baseLocale.pricing_paid_feature6

  public val pricing_paid_feature7: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature7 ?:
        baseLocale.pricing_paid_feature7

  public val pricing_paid_feature8: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature8 ?:
        baseLocale.pricing_paid_feature8

  public val pricing_paid_feature9: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature9 ?:
        baseLocale.pricing_paid_feature9

  public val pricing_paid_feature10: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature10 ?:
        baseLocale.pricing_paid_feature10

  public val pricing_paid_feature11: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature11 ?:
        baseLocale.pricing_paid_feature11

  public val pricing_paid_feature12: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature12 ?:
        baseLocale.pricing_paid_feature12

  public val pricing_paid_feature13: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature13 ?:
        baseLocale.pricing_paid_feature13

  public val pricing_paid_feature14: String
    get() = locales[getCurrentLanguageCode()]?.pricing_paid_feature14 ?:
        baseLocale.pricing_paid_feature14

  public val new_feature_title: String
    get() = locales[getCurrentLanguageCode()]?.new_feature_title ?: baseLocale.new_feature_title

  public val new_feature_link_title: String
    get() = locales[getCurrentLanguageCode()]?.new_feature_link_title ?:
        baseLocale.new_feature_link_title

  public val new_feature_description: String
    get() = locales[getCurrentLanguageCode()]?.new_feature_description ?:
        baseLocale.new_feature_description

  public val new_feature_recommend: String
    get() = locales[getCurrentLanguageCode()]?.new_feature_recommend ?:
        baseLocale.new_feature_recommend
}
