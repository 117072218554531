package cl.yoroi.app.web.comanda.components.app_feature

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import cl.yoroi.app.web.comanda.components.ObserveViewportEntered
import cl.yoroi.app.web.comanda.models.Feature
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun AppFeatureCard(
    feature: Feature,
    number : Int
) {
//    val scope = rememberCoroutineScope()
    ObserveViewportEntered(
        sectionId = feature.id,
        distanceFromTop = number*200.0,
        onViewPortEntered = {
//            scope.launch{
//                subtitleMargin = 0.px
//                if(alignment == Alignment.Start){
//                    delay(25)
//                }
//                titleMargin = 0.px
//            }
        }
    )
    val breakpoint = rememberBreakpoint()
    SimpleGrid(
        numColumns = numColumns(base = 1, md = 2),
        modifier = Modifier
            .id(feature.id)
            .fillMaxWidth(
                if(breakpoint <=  Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .thenIf(
                condition = breakpoint > Breakpoint.MD,
                other = Modifier.height(Res.Dimens.MAX_CARD_HEIGHT.px)
            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
        )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(Colors.White)
    ) {
        LeftSide(
            breakpoint = breakpoint,
            feature = feature
        )
        RightSide(
            breakpoint = breakpoint,
            feature= feature
        )
    }
}