package cl.yoroi.app.web.comanda.components.pricing

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.models.PricingFeature
import cl.yoroi.app.web.comanda.models.PricingType
import cl.yoroi.app.web.comanda.styles.ButtonStyle
import cl.yoroi.app.web.comanda.styles.PricingRecommendedStyle
import cl.yoroi.app.web.comanda.styles.PricingStyle
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.icons.fa.FaAndroid
import com.varabyte.kobweb.silk.components.icons.fa.FaApple
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.RectF
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.window
import org.jetbrains.compose.web.css.AlignSelf
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import strings.ResStrings

@Composable
fun PricingItem (
    breakpoint: Breakpoint,
    pricing: PricingType
) {
    val style = if(pricing.recommended) PricingRecommendedStyle else PricingStyle
    Column(
        modifier = style.toModifier()
            .margin(left = 13.percent)
            .backgroundColor(if(pricing.recommended) Res.Theme.BROWN8A.color else Res.Theme.BROWN2A.color)
            .clip(RectF(10.px))
            .padding(all = 50.px)
            .alignSelf(AlignSelf.Center),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = if(breakpoint <= Breakpoint.SM)
            Alignment.CenterHorizontally else Alignment.Start
    ) {
        if(pricing.recommended){
            SpanText(
                text = ResStrings.pricing_recommended,
                modifier = Modifier
                    .padding(10.px)
                    .fontFamily(Res.String.ROBOTO_CONDENSED)
                    .color(if(pricing.recommended) Res.Theme.Secondary.color else Color.black)
                    .backgroundColor(Res.Theme.BROWN.color)
                    .clip(RectF(10.px))
                    .fontSize(12.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if(breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )
        }
        SpanText(
            text = pricing.name,
            modifier = Modifier
                .margin(bottom = 12.px)
                .fontFamily(Res.String.ROBOTO_CONDENSED)
                .color(if(pricing.recommended) Res.Theme.Secondary.color else Color.black)
                .fontSize(30.px)
                .fontWeight(FontWeight.Bold)
                .textAlign(
                    if(breakpoint <= Breakpoint.SM) TextAlign.Center
                    else TextAlign.Start
                )
        )
        Row(
            verticalAlignment = Alignment.Bottom
        ) {
            SpanText(
                text = pricing.price,
                modifier = Modifier
                    .margin(bottom = 12.px)
                    .fontFamily(Res.String.ROBOTO_CONDENSED)
                    .color(if(pricing.recommended) Res.Theme.Secondary.color else Color.black)
                    .fontSize(50.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if (breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )
            Box(modifier = Modifier.width(8.px)) {}
            SpanText(
                text = pricing.periodicity,
                modifier = Modifier
                    .margin(bottom = 36.px)
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .color(if(pricing.recommended) Res.Theme.Secondary.color else Color.black)
                    .opacity(50.percent)
                    .lineHeight(2)
                    .fontSize(14.px)
                    .fontWeight(FontWeight.Bold)
                    .textAlign(
                        if(breakpoint <= Breakpoint.SM) TextAlign.Center
                        else TextAlign.Start
                    )
            )

        }
        Box (modifier = Modifier.height(8.px)){}
        Button(
            modifier = ButtonStyle.toModifier().margin(bottom = 20.px),
            size = ButtonSize.LG,
            onClick = {
                window.location.href = Res.String.ANDROID_URL
            }
        ){
            FaAndroid(
                modifier = Modifier.margin(right = 12.px).color(Res.Theme.Secondary.color)
            )
            SpanText(
                modifier = Modifier
                    .fontSize(14.px)
                    .color(Res.Theme.Secondary.color)
                    .fontWeight(FontWeight.Bold)
                    .fontFamily(Res.String.ROBOTO_REGULAR),
                text = Res.String.BUTTON_TEXT_ANDROID
            )
        }
        Button(
            modifier = ButtonStyle.toModifier().margin(bottom = 50.px),
            enabled = false,
            size = ButtonSize.LG,
            onClick = {
                window.location.href = Res.String.IOS_URL
            }
        ){
            FaApple(
                modifier = Modifier.margin(right = 12.px).color(Res.Theme.Secondary.color)
            )
            SpanText(
                modifier = Modifier
                    .fontSize(14.px)
                    .color(Res.Theme.Secondary.color)
                    .fontWeight(FontWeight.Bold)
                    .fontFamily(Res.String.ROBOTO_REGULAR),
                text = Res.String.BUTTON_TEXT_IOS + " " + ResStrings.soon_label
            )
        }
        Surface(
            modifier = Modifier
                .height(4.px)
                .width(40.px)
                .margin(bottom = 24.px)
                .background(Res.Theme.BROWN.color)
                .align(
                    if(breakpoint <= Breakpoint.SM) Alignment.CenterHorizontally
                    else Alignment.Start
                )
        ){}
        pricing.features.forEach { feature ->
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                feature.icon(
                    Modifier
                        .color(Res.Theme.Secondary.color)
                        .backgroundColor(Res.Theme.Primary.color)
                        .padding(3.px)
                        .clip(Circle())
                )
                Box(modifier = Modifier.width(8.px)) {}
                SpanText(
                    text = feature.title,
                    modifier = Modifier
                        .fontFamily(Res.String.ROBOTO_REGULAR)
                        .color(if(pricing.recommended) Res.Theme.Secondary.color else Color.black)
                        .opacity(50.percent)
                        .lineHeight(2)
                        .fontSize(14.px)
                        .fontWeight(FontWeight.Bold)
                        .textAlign(
                            if (breakpoint <= Breakpoint.SM) TextAlign.Center
                            else TextAlign.Start
                        )
                )
            }
        }


    }
}