package cl.yoroi.app.web.comanda.models

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier

data class PricingType(
    val name : String,
    val price : String,
    val recommended : Boolean,
    val periodicity : String,
    val features : List<PricingFeature>
)

data class PricingFeature (
    val icon : @Composable (Modifier) -> Unit,
    val title : String
)