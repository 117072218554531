package cl.yoroi.app.web.comanda.pages

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.components.pricing.PricingItem
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import strings.ResStrings

@Composable
fun PricingPage() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier
            .id("pricing")
            .rowGap(3.cssRem).columnGap(20.px)
            .fillMaxWidth(
                if (breakpoint <= Breakpoint.MD) 100.percent
                else Res.Dimens.MAX_CARD_WIDTH.px
            )
            .fillMaxHeight()
//            .thenIf(
//                condition = breakpoint > Breakpoint.MD,
//                other = Modifier.height(Res.Dimens.MAX_CARD_HEIGHT.px)
//            )
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 12.px)
            .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
            .background(Colors.White)
            .alignContent(AlignContent.Center)
    ) {
        SpanText(
            text = ResStrings.pricing_title,
            modifier = Modifier
                .margin(top = 50.px, left = if (breakpoint <= Breakpoint.SM) 0.px else 50.px)
                .fillMaxWidth()
                .fontFamily(Res.String.ROBOTO_CONDENSED)
                .color(Colors.Black)
                .fontSize(50.px)
                .fontWeight(FontWeight.Bold)
                .textAlign(
                    if (breakpoint <= Breakpoint.SM) TextAlign.Center
                    else TextAlign.Start
                )
        )
        Surface(
            modifier = Modifier
                .height(4.px)
                .width(40.px)
                .margin(bottom = 24.px, left = if (breakpoint <= Breakpoint.SM) 0.px else 50.px)
                .background(Res.Theme.BROWN.color)
                .align(
                    if (breakpoint <= Breakpoint.SM) Alignment.CenterHorizontally
                    else Alignment.Start
                )
        ) {}
        SimpleGrid(
            numColumns = numColumns(base = 1, md = 2),
            modifier = Modifier
                .id("pricing")
                .rowGap(3.cssRem).columnGap(20.px)
                .fillMaxWidth(
                    if (breakpoint <= Breakpoint.MD) 100.percent
                    else Res.Dimens.MAX_CARD_WIDTH.px
                )
                .fillMaxHeight()
//            .thenIf(
//                condition = breakpoint > Breakpoint.MD,
//                other = Modifier.height(Res.Dimens.MAX_CARD_HEIGHT.px)
//            )
//                .boxShadow(
//                    color = Colors.Black.copy(alpha = 10),
//                    blurRadius = 50.px,
//                    spreadRadius = 50.px
//                )
//                .padding(all = 12.px)
//                .borderRadius(r = Res.Dimens.BORDER_RADIUS.px)
//                .background(Colors.White)
                .alignContent(AlignContent.Center)

        ) {
            Res.Arrays.PRICING_TYPES.forEach { type ->
                PricingItem(
                    breakpoint = breakpoint,
                    pricing = type
                )

            }
        }
    }
}