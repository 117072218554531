package cl.yoroi.app.web.comanda.components

import androidx.compose.runtime.*
import cl.yoroi.app.web.comanda.styles.NavigationItemStyle
import cl.yoroi.app.web.comanda.utils.Res
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import strings.ResStrings

@Composable
fun OverflowMenu(
    onMenuClosed : () -> Unit
) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var translateX by remember { mutableStateOf((-100).percent) }
    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(breakpoint){
        translateX= 0.percent
        opacity = 100.percent
        if(breakpoint > Breakpoint.MD){
            scope.launch {
                translateX = (-100).percent
                opacity =0.percent
                delay(500)
                onMenuClosed()
            }
        }
    }

    Column (
        modifier = Modifier
            .fillMaxWidth()
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(2)
            .opacity(opacity)
            .backgroundColor(argb(a =0.5f,r = 0.0f, g=0.0f, b= 0.0f))
            .transition(Transition.of(property = "opacity", duration = 500.ms))
    ){
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .padding(25.px)
                .width(if(breakpoint<Breakpoint.MD) 50.percent else 25.percent)
                .overflow(Overflow.Auto)
                .scrollBehavior(ScrollBehavior.Smooth)
                .backgroundColor(Colors.White)
                .translateX(tx =  translateX)
                .transition(Transition.of(property = "translate", duration = 500.ms))
        ) {
            Row(
                modifier = Modifier
                    .margin(bottom = 25.px),
                verticalAlignment = Alignment.CenterVertically
            )
            {
                FaXmark(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .margin(right = 20.px, bottom = 3.px)
                        .onClick {
                            scope.launch {
                                translateX= (-100).percent
                                opacity=0.percent
                                delay(500)
                                onMenuClosed()
                            }
                        },
                    size = IconSize.LG
                )
                Image(
                    modifier = Modifier.size(80.px).clip(Circle()),
                    src = Res.Image.LOGO,
                )
            }
            Res.Arrays.FEATURES.forEach { section ->
                Link(
                    modifier = NavigationItemStyle.toModifier()
                        .fontFamily(Res.String.ROBOTO_REGULAR)
                        .padding(
                            bottom = 10.px
                        )
                        .fontSize(16.px)
                        .fontWeight(FontWeight.Normal)
                        .textDecorationLine(TextDecorationLine.None)
                        .onClick {
                            scope.launch {
                                translateX= (-100).percent
                                opacity=0.percent
                                delay(500)
                                onMenuClosed()
                            }
                        }
                    ,
                    path = section.path,
                    openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                    text = section.linkTitle
                )
            }
            Link(
                modifier = NavigationItemStyle.toModifier()
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .padding(
                        bottom = 10.px
                    )
                    .fontSize(16.px)
                    .fontWeight(FontWeight.Normal)
                    .textDecorationLine(TextDecorationLine.None)
                    .onClick {
                        scope.launch {
                            translateX= (-100).percent
                            opacity=0.percent
                            delay(500)
                            onMenuClosed()
                        }
                    }
                ,
                path = "#pricing",
                openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                text = ResStrings.pricing_title
            )
            Link(
                modifier = NavigationItemStyle.toModifier()
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .padding(
                        bottom = 10.px
                    )
                    .fontSize(16.px)
                    .fontWeight(FontWeight.Normal)
                    .textDecorationLine(TextDecorationLine.None)
                    .onClick {
                        scope.launch {
                            translateX= (-100).percent
                            opacity=0.percent
                            delay(500)
                            onMenuClosed()
                        }
                    }
                ,
                path = "#newfeature",
                openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE,
                text = ResStrings.new_feature_link_title
            )

        }
    }
}