package cl.yoroi.app.web.comanda.pages

import androidx.compose.runtime.Composable
import cl.yoroi.app.web.comanda.components.Header
import cl.yoroi.app.web.comanda.components.SocialBar
import cl.yoroi.app.web.comanda.styles.ButtonStyle
import cl.yoroi.app.web.comanda.styles.MainImageStyle
import cl.yoroi.app.web.comanda.utils.Res
import cl.yoroi.app.web.comanda.utils.Res.Dimens.SECTION_WIDTH
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.ButtonSize
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaAndroid
import com.varabyte.kobweb.silk.components.icons.fa.FaApple
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.CircleF
import com.varabyte.kobweb.silk.theme.shapes.RectF
import com.varabyte.kobweb.silk.theme.shapes.clip
import kotlinx.browser.window
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import strings.ResStrings

@Composable
fun MainSection(onMenuClicked :() -> Unit) {
    Box(
        modifier = Modifier
            .id("home")
            .maxWidth(SECTION_WIDTH.px),
        contentAlignment = Alignment.TopCenter
    ){
        MainBackground()
        MainContent(onMenuClicked)

    }
}

@Composable
fun MainBackground(){
    val breakpoint = rememberBreakpoint()
    Image(
        modifier = Modifier
            .fillMaxSize()
            .objectFit(ObjectFit.Cover),
        src = if(breakpoint < Breakpoint.MD) Res.Image.BACKGROUNDSM else Res.Image.BACKGROUND,
    )
}
@Composable
fun MainContent(onMenuClicked :() -> Unit){
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier
            .fillMaxSize(),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Header(onMenuClicked)
        Column (
            modifier = Modifier
                .fillMaxSize(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            SimpleGrid(
                modifier = Modifier
                    .fillMaxWidth(
                        if (breakpoint >= Breakpoint.MD) 80.percent else 90.percent
                    ),
                numColumns = numColumns(md = 2, base = 1)
            ){
                MainText(breakpoint)
                MainVideo()

            }
        }
    }
}

@Composable
fun MainText(breakpoint: Breakpoint){
    Row (
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ){
//        if (
//            breakpoint> Breakpoint.MD
//        ) {
//            SocialBar()
//        }

        Column {
            P (
                attrs = Modifier
                    .margin(topBottom = 0.px)
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .padding(50.px)
                    .fontSize(if(breakpoint >= Breakpoint.LG) 45.px else 25.px)
                    .backgroundColor(color = Res.Theme.Secondary.color)
                    .clip(RectF(cornerRadius = 10.px))
                    .fontWeight(FontWeight.Bold)
                    .color(Res.Theme.Primary.color)
                    .textAlign(TextAlign.Center)
                    .toAttrs()
            ){
                Text(ResStrings.app_slogan)
            }

            P (
                attrs = Modifier
                    .margin(top = 25.px,bottom = 0.px)
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .fontSize(if(breakpoint >= Breakpoint.LG) 68.px else 40.px)
                    .fontWeight(FontWeight.Bolder)
                    .color(Res.Theme.Secondary.color)
                    .toAttrs()
            ){
                Text("")
            }
            com.varabyte.kobweb.silk.components.forms.Button(
                modifier = ButtonStyle.toModifier()
                    .margin(bottom = 20.px)
                    .align(if(breakpoint  >= Breakpoint.LG) Alignment.Start else Alignment.CenterHorizontally)
                ,
                size = ButtonSize.LG,
                onClick = {
                    window.location.href = Res.String.ANDROID_URL
                }
            ) {
                FaAndroid(
                    modifier = Modifier.margin(right = 12.px).color(Res.Theme.Secondary.color)
                )
                SpanText(
                    modifier = Modifier
                        .fontSize(14.px)
                        .color(Res.Theme.Secondary.color)
                        .fontWeight(FontWeight.Bold)
                        .fontFamily(Res.String.ROBOTO_REGULAR),
                    text = Res.String.BUTTON_TEXT_ANDROID
                )
            }
            com.varabyte.kobweb.silk.components.forms.Button(
                modifier = ButtonStyle.toModifier()
                    .margin(bottom = 50.px)
                    .align(if(breakpoint  >= Breakpoint.LG) Alignment.Start else Alignment.CenterHorizontally)
                ,
                enabled = false,
                size = ButtonSize.LG,
                onClick = {
                    window.location.href = Res.String.IOS_URL
                }
            ) {
                FaApple(
                    modifier = Modifier.margin(right = 12.px).color(Res.Theme.Secondary.color)
                )
                SpanText(
                    modifier = Modifier
                        .fontSize(14.px)
                        .color(Res.Theme.Secondary.color)
                        .fontWeight(FontWeight.Bold)
                        .fontFamily(Res.String.ROBOTO_REGULAR),
                    text = Res.String.BUTTON_TEXT_IOS + " " + ResStrings.soon_label
                )
            }
            P (
                attrs = Modifier
                    .margin(top = 10.px,bottom = 5.px)
                    .padding (50.px)
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Bold)
                    .backgroundColor(color = Res.Theme.Primary.color)
                    .clip(RectF(cornerRadius = 10.px))
                    .color(Res.Theme.Secondary.color)
                    .toAttrs()
            ){
                Text(ResStrings.app_description)
            }

            P (
                attrs = Modifier
                    .margin(bottom = 25.px)
                    .maxWidth(400.px)
                    .fontFamily(Res.String.ROBOTO_REGULAR)
                    .fontSize(15.px)
                    .fontStyle(FontStyle.Italic)
                    .fontWeight(FontWeight.Normal)
                    .boxShadow(color = Res.Theme.Primary.color)
                    .color(Res.Theme.Secondary.color)
                    .toAttrs()
            ){
                Text("")
            }
//            Button (
//                attrs = MainButtonStyle.toModifier()
//                    .height(40.px)
//                    .border(width = 0.px)
//                    .borderRadius(r = 5.px)
//                    .backgroundColor(Theme.Primary.rgb)
//                    .color(Colors.White)
//                    .cursor(Cursor.Pointer)
//                    .toAttrs()
//            ){
//                Link(
//                    modifier = Modifier
//                        .color(Colors.White)
//                        .textDecorationLine(TextDecorationLine.None),
//                    text = "Contact Me",
//                    path = Section.Contact.path
//                )
//
//            }

        }

    }
}

@Composable
fun MainVideo(){
    Column (
        modifier = Modifier
            .padding(left = 20.px)
            .fillMaxSize()
            .fillMaxHeight(),
        verticalArrangement = Arrangement.Bottom
    ){
        Video(
            attrs = MainImageStyle
                .toModifier().fillMaxWidth()
                .maxHeight(800.px)
                .margin(bottom = 25.px)
                .opacity(80.percent)
                .attrsModifier {
                    attr("controls","false")
                    attr("autoplay","")
                    attr("loop", "")
                }
                .toAttrs(),
        ){
            Source(
                attrs = {
                    attr("src",ResStrings.header_video)
                    attr("type","video/mp4")
                }
            )
        }
    }
}